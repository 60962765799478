import React, { useCallback } from "react";
import { PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { LayoutGoal, SEO } from "@components";
import { Edit } from "images/Goal";
import { useAuth, useURLParams } from "@hooks";
import { useGoals } from "@apollo";

const EditGoal: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const { goals } = useGoals();

  const goal = useCallback(() => {
    if (useURLParams() !== undefined) {
      const goalfiltered = goals.filter((obj) => obj.id == useURLParams()[0].id);
      return goalfiltered[0];
    } else {
      return undefined;
    }
  }, [goals]);

  return (
    <>
      <SEO />
      <LayoutGoal
        title="Editar objetivo: "
        secondTitle={goal()?.name}
        buttonContinue="Guardar Cambios"
        icon={Edit}
        goal={goal()?.goal}
        goalId={goal()?.id}
        goalPeriod={goal()?.info.period}
        goalType={goal()?.type}
      />
    </>
  );
};

export default EditGoal;

const useStyles = makeStyles((theme) => ({
  headerLayout: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    paddingTop: 50,
    marginBottom: "2rem",
    "& img": {
      width: 140,
      height: 140,
    },
  },
  container: {
    display: "grid",
    width: "100%",
    padding: "25px",
    gridTemplateColumns: "40% 40%",
    gridTemplateAreas: "'goal plazo' 'react meta'",
    "& input": {
      width: 400,
    },
  },
  goal: {
    gridArea: "goal",
    "&>div": {
      width: 428,
    },
  },
  titleReact: {
    gridArea: "react",
  },
  plazo: {
    gridArea: "plazo",
  },
  meta: {
    gridArea: "meta",
  },
  txts: {
    paddingTop: "1rem",
    "& span": {
      display: "block",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    color: "#393e44",
    display: "block",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    display: "block",
    color:theme.palette.text.secondary,
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
    width: "75%",
    marginTop: 50,
    marginBottom: "5rem",
    "& .outlined, .primary": {
      width: 185,
    },
    "& .primary": {
      marginLeft: 20,
    },
  },
}));
